import { useEffect, useState } from 'react';
import * as ss58 from '@chainflip/utils/ss58';
import { abbreviate } from '@chainflip/utils/string';
import { useMutation } from '@tanstack/react-query';
import { useAccount } from 'wagmi';
import { flip$ } from '@/shared/assets/tokens';
import { Modal, MaxAmountInput, Button, Link } from '@/shared/components';
import Checkbox from '@/shared/components/flip-ui-kit/Checkbox';
import type { ModalProps } from '@/shared/components/molecules/Modal';
import QuestionMarkTooltip from '@/shared/components/QuestionMarkTooltip';
import { useFundingSDK } from '@/shared/hooks';
import useToast from '@/shared/hooks/useToast';
import useTracking from '@/shared/hooks/useTracking';
import { CheckmarkIcon } from '@/shared/icons/large';
import { FLIP_SYMBOL } from '@/shared/utils';
import { tryGetEthersError } from '@/shared/utils/functions';
import { ethTxLink } from '@/shared/utils/strings';
import TokenAmount from '@/shared/utils/TokenAmount';
import { BidPreview } from './BidPreview';
import { useBigNumber } from '../hooks/useBigNumber';
import { StakeEvents, type StakeTrackEvents } from '../types/track';

export interface FundAccountModalProps
  extends Pick<ModalProps, 'active' | 'afterLeave' | 'children'> {
  nodeAddress: string;
  nodeCurrentBalance: TokenAmount;
  buttonsDisabled?: boolean;
  onClose: () => void;
  onFunded?: () => void;
  displayWarning?: boolean;
  type: 'register' | 'increase';
}

export const FundAccountModal = ({
  type,
  active,
  onClose,
  afterLeave,
  children,
  nodeAddress,
  nodeCurrentBalance,
  onFunded,
  buttonsDisabled: buttonsDisabledProp = false,
  displayWarning = false,
}: FundAccountModalProps): JSX.Element => {
  const track = useTracking<StakeTrackEvents>();
  const {
    flipBalance: flipBalanceString,
    fundStateChainAccount,
    minimumFunding,
    approveFlip,
  } = useFundingSDK();
  const { address: connectedAddress } = useAccount();
  const [amount, setAmount, amountBigNumber] = useBigNumber();
  const [amountErrorMessage, setAmountErrorMessage] = useState('');
  const [step, setStep] = useState<'approve' | 'fund' | 'success'>('approve');
  const [txHash, setTxHash] = useState<string | undefined>();
  const flipBalance = new TokenAmount(flipBalanceString || 0, flip$.decimals);
  const [warningAcknowledged, setWarningAcknowledged] = useState(false);
  const toast = useToast('funding');

  const hasAmountError = amountErrorMessage.length > 0;
  const minimumFundingMessage =
    minimumFunding && `Minimum amount: ${minimumFunding.toFixed()} ${FLIP_SYMBOL}`;
  const buttonsDisabled =
    buttonsDisabledProp ||
    !amount ||
    Boolean(amountErrorMessage) ||
    (displayWarning && !warningAcknowledged);

  useEffect(() => {
    if (amount === '') {
      setAmountErrorMessage('');
      return;
    }
    if (minimumFunding && minimumFunding.gt(TokenAmount.fromWholeUnits(amount))) {
      setAmountErrorMessage(minimumFundingMessage as string);
      return;
    }
    if (TokenAmount.fromWholeUnits(amount).gt(flipBalance)) {
      setAmountErrorMessage(`Amount above wallet ${FLIP_SYMBOL} balance`);
      return;
    }
    setAmountErrorMessage('');
  }, [amount, minimumFunding, flipBalance]);

  const { mutate: approve, isPending: isApproving } = useMutation({
    mutationFn: async (_: React.MouseEvent) => {
      track(
        type === 'register'
          ? StakeEvents.CtaRegisterApproveToken
          : StakeEvents.CtaIncreaseApproveToken,
        {
          props: {
            connectedWallet: connectedAddress as string,
            scAccount: nodeAddress,
            path: window.location.pathname,
            amount,
          },
        },
      );

      toast.loading('Waiting for token approval...');
      const approved = await approveFlip(amountBigNumber);
      if (!approved) {
        toast.error('Failed to approve funds :(');
      }
    },
    onError(error) {
      toast.error('Failed to approve', {
        description: tryGetEthersError(error, 'Approval'),
      });
    },
    onSuccess() {
      const tokenAmount = new TokenAmount(amountBigNumber);
      toast.success(`${tokenAmount.toPreciseFixedDisplay()} ${FLIP_SYMBOL} approved`);
      setStep('fund');
    },
  });

  const { mutate: fundAccount, isPending: isFunding } = useMutation({
    mutationFn: async (_: React.MouseEvent) => {
      track(
        type === 'register' ? StakeEvents.CtaRegisterAddFunds : StakeEvents.CtaIncreaseAddFunds,
        {
          props: {
            connectedWallet: connectedAddress as string,
            scAccount: nodeAddress,
            path: window.location.pathname,
            amount,
          },
        },
      );

      const validatorIdHex = ss58.toPublicKey(nodeAddress);
      const fundTxHash = await fundStateChainAccount(validatorIdHex, amountBigNumber);

      if (fundTxHash) {
        track(
          type === 'register' ? StakeEvents.RegisterNodeSuccess : StakeEvents.IncreaseFundsSuccess,
          {
            props: {
              connectedWallet: connectedAddress as string,
              scAccount: nodeAddress,
              path: window.location.pathname,
              amount,
            },
          },
        );

        setTxHash(fundTxHash);
      }
    },
    onError(error) {
      toast.error('Failed to fund account', {
        description: tryGetEthersError(error, 'Funding'),
      });
    },
    onSuccess() {
      setStep('success');
      setAmount('');
      onFunded?.();
    },
  });

  return (
    <Modal
      active={active}
      heading={type === 'register' ? 'Register new node' : 'Increase Balance'}
      subheading={
        type === 'increase' ? 'Increase the total balance for this node by adding funds' : undefined
      }
      onCancel={onClose}
      afterLeave={() => {
        setStep('approve');
        afterLeave?.();
      }}
    >
      {step === 'success' ? (
        <>
          <div className="my-[170px] flex flex-col items-center space-y-4">
            <CheckmarkIcon width={54} height={54} className="text-cf-green-1" />
            <div className="flex flex-col space-y-2 text-center text-22 text-white">
              <div>{type === 'register' ? 'Register successful' : 'Funding successful'}</div>
              <Link href={ethTxLink(txHash as string)} className="underline" target="_blank">
                {abbreviate(txHash)}
              </Link>
              <div className="text-16">
                {type === 'register'
                  ? `Your node will appear after changing the account's role to validator`
                  : 'Your balance will update soon'}
              </div>
            </div>
          </div>
          <div className="-mb-5 mt-9">
            <Button onClick={onClose} fullWidth>
              Done
            </Button>
          </div>
        </>
      ) : (
        <>
          <div className="mt-4 w-full space-y-6 px-1.5 text-left">
            {children}
            <MaxAmountInput
              placeholder="0.00"
              label="Amount"
              failure={hasAmountError}
              message={hasAmountError ? amountErrorMessage : minimumFundingMessage}
              value={amount?.toString()}
              setAmountValue={setAmount}
              maxAmount={flipBalance}
              maxAmountLabel="Wallet balance"
            />
            <BidPreview
              className="border border-cf-light-1"
              totalBalance={nodeCurrentBalance.add(amountBigNumber.toString())}
            />
          </div>
          {/* offset padding of modal content with negative margin to place buttons at bottom of modal */}
          <div className="-mb-5 mt-9 space-y-3">
            {displayWarning && (
              <div className="flex items-center space-x-3">
                <Checkbox
                  id="checker"
                  checked={warningAcknowledged}
                  setChecked={setWarningAcknowledged}
                />
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor="checker" className="cursor-pointer">
                  I acknowledge that funding a validator I don&apos;t control will result in loss of
                  funds.
                </label>
              </div>
            )}

            <Button
              onClick={approve}
              disabled={step !== 'approve' || isApproving || buttonsDisabled}
              fullWidth
              loading={isApproving && 'iconOnly'}
            >
              <QuestionMarkTooltip
                content={`You need to give our state chain gateway contract permission to use your ${FLIP_SYMBOL}`}
              />
              <span className="ml-[5px]">Allow us to use your {FLIP_SYMBOL}</span>
            </Button>
            <Button
              onClick={fundAccount}
              disabled={step !== 'fund' || isFunding || buttonsDisabled}
              fullWidth
              loading={isFunding && 'iconOnly'}
            >
              Add funds
            </Button>
          </div>
        </>
      )}
    </Modal>
  );
};
